.header__search {
  .header__search-btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .header__search-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

//Header css
.mega-menu {
  display: none;
  left: 58px;
  position: absolute;
  text-align: left;
  min-width: 70%;
  width: auto;
  margin-top: 0;
  z-index: 9999;
  max-width: 90%;
  .mega-menu-ul {
    display: flex;
    // column-count: 5;
    // column-fill: balance;
    //columns: 5;
    // display: grid;
    // grid-template-columns: repeat(5, 1fr); /* Five columns of equal width */
    // grid-template-rows: repeat(2, 300px);
  }
}

.hoverable:hover .mega-menu {
  display: block;
}
.header__popover {
  font-family: var(--p-font-family-sans);
  .ant-popover-inner {
    padding: 10px 0 !important;
    border: 1px solid rgb(var(--color-border-primary));
  }
}

.suggestions__wrapper {
  form {
    display: inline-block;
    width: 100%;
    max-width: 725px;
    position: relative;
    border-radius: 8px;
    background-color: #f0f5ff;
    .search__input-wrapper {
      width: 100%;
      display: flex;
      .search__input {
        width: 100%;
      }
    }
  }
}

/******************** Mobile header *********************/
.mobile__header {
  .header__wrapper {
    padding: 10px 10px;
    display: flex;
    align-items: center;
  }
  .header__menu {
    margin-right: 5px;
    display: flex;
  }
  .mobile__logo {
    width: 40px;
  }
  .header__logo__wrapper {
    margin-right: 5px;
  }
  .suggestions__wrapper {
    margin-right: 10px;
  }
  .header__search-btn {
    width: 42px !important;
  }
  .header__search-input {
    border-color: rgb(152 162 179 / 30%) !important;
  }
}

//Mobile Drawer
.mobile__drawer {
  .ant-drawer-content-wrapper {
    max-width: 400px !important;
  }
  .ant-drawer-body {
    padding: 0 !important;
  }
  .sidebar__container {
    .sidebar__header {
    }
  }
}
.icon__btn .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; /* Adjust this width to your needs */
  display: inline-block;
  vertical-align: bottom;
}
