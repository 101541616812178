.suggestions {
  border-radius: 0 0 8px 8px;
  position: absolute;
  background-color: #fff;
  color: #000;
  z-index: 9;
  top: 40px;
  display: block;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  li {
    padding: 6px 12px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-size: 14px;
    cursor: pointer;
  }
  .suggestion__heading {
    background: #e7ecf0;
    color: #1e7fd9;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .suggestion__title {
    display: flex;
    align-items: start !important;
    flex-direction: row !important;
  }
  .suggestion__icon {
    padding-top: 4px;
    margin-right: 10px;
  }
  .suggestion__title:hover {
    background: #eff5fa;
    color: #000;
  }
  .selected {
    background: #eff5fa;
    color: #000;
  }
}
